import { MDXProvider } from '@mdx-js/react'
import { Blockquote } from 'components/BlockQuote'
import Breadcrumbs, { Crumb } from 'components/Breadcrumbs'
import { Calendar, Edit, Issue } from 'components/Icons/Icons'
import { InlineCode } from 'components/InlineCode'
import Layout from 'components/Layout'
import { formattedDate } from 'utils/dateutils'
import Link from 'components/Link'
import PostLayout from 'components/PostLayout'
import Text from 'components/PostLayout/Text'
import Topics from 'components/PostLayout/Topics'
import ShareLinks from 'components/PostLayout/ShareLinks'
import SidebarSection from 'components/PostLayout/SidebarSection'
import PageViews from 'components/PostLayout/PageViews'
import Contributors, { Contributor } from 'components/PostLayout/Contributors'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import { ZoomImage } from 'components/ZoomImage'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { MdxCodeBlock } from 'components/CodeBlock'
import { shortcodes } from '../mdxGlobalComponents'
import { NewsletterForm } from 'components/NewsletterForm'
import slugify from 'slugify'
import { Heading } from 'components/Heading'
import { UnorderedList } from 'components/UnorderedList'
import TutorialsSlider from 'components/TutorialsSlider'
import { useLayoutData } from 'hooks/useLayoutData'
import { getDirection } from 'utils/translations'

const A = (props) => <Link {...props} dir="auto" className="text-red hover:text-red font-semibold" />

const Title = ({ children, className = '' }) => {
    return <h1 className={`text-3xl md:text-4xl lg:text-4xl mb-1 mt-6 lg:mt-1 ${className}`}>{children}</h1>
}

// export const Head = ({ location, params, data: { postData } , pageContext: { documentationNav} }) =>{
// const featuredImg = postData?.frontmatter?.featuredImage || postData?.parent?.cover?.image
//     return (
//     <SEO2
//     title={postData?.frontmatter?.title + ''}
//     description={postData?.frontmatter?.description || postData?.excerpt}
//     article
//     image={ postData?.frontmatter?.featuredImageType === 'full' ? `/og-images/${postData?.frontmatter?.slug?.replace(/\//g, '')}.jpeg` : featuredImg?.publicURL }
// />
//   )
// }
export const Intro = ({
    featuredImage,
    featuredVideo,
    title,
    featuredImageType,
    contributors,
    titlePosition = 'bottom',
    date,
    tags,
}) => {
    return (
        <div dir="auto" className="lg:mb-7 mb-4 overflow-hidden">
            {featuredVideo && <iframe src={featuredVideo} />}
            {!featuredVideo && featuredImage && (
                <div className="relative flex flex-col">
                    <GatsbyImage
                        className={`rounded-md z-0 relative ${featuredImageType === 'full'
                                ? `before:h-3/4 before:left-0 before:right-0 ${titlePosition === 'bottom' ? 'before:bottom-0' : 'before:top-0'
                                } before:z-[1] before:absolute ${titlePosition === 'bottom' ? 'before:bg-gradient-to-t' : 'before:bg-gradient-to-b'
                                } before:from-black/75 [text-shadow:0_2px_10px_rgba(0,0,0,0.4)] lg:before:block before:hidden`
                                : ''
                            }`}
                        image={getImage(featuredImage)}
                    />
                    {featuredImageType === 'full' && (
                        <>
                            <div
                                className={`lg:absolute flex flex-col lg:px-8 lg:py-4 ${titlePosition === 'bottom' ? 'bottom-0' : 'top-0'
                                    }`}
                            >
                                <p className="m-0 opacity-70 order-last lg:order-first lg:text-white">{formattedDate(date)}</p>
                                <Title dir="auto" className="lg:text-white text-primary">{title}</Title>
                            </div>
                        </>
                    )}
                </div>
            )}
            {(featuredVideo || featuredImageType !== 'full') && <Title className="lg:mt-7 mt-4">{title}</Title>}
            {contributors && (
                <div className="lg:hidden my-3">
                    {contributors?.map((contributor) => (
                        <Contributor image={contributor?.parent?.cover?.image || contributor?.image} name={contributor?.name} key={contributor?.name} text />
                    ))}
                </div>
            )}
        </div>
    )
}

const BlogPostSidebar = ({ contributors , date, filePath, title, tags, location, pageViews }) => {
    return (
        <>
            {contributors && (
                <SidebarSection>
                    <Contributors contributors={contributors} />
                </SidebarSection>
            )}
            {pageViews?.length > 0 && (
                <SidebarSection>
                    <PageViews pageViews={pageViews?.toLocaleString()} />
                </SidebarSection>
            )}
            {tags?.length > 0 && (
                <SidebarSection title={`Tag${tags?.length === 1 ? '' : 's'}`}>
                    <Topics
                        topics={tags?.map((tag) => ({ name: tag, url: `/blog/tags/${slugify(tag, { lower: true })}` }))}
                    />
                </SidebarSection>
            )}
            <SidebarSection>
                <NewsletterForm sidebar />
            </SidebarSection>
        </>
    )
}

export default function GoogleDocsBlogPost(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { postData } = data
    const { body, excerpt, fields, frontmatter, parent } = postData
    const { date, featuredImage, title, name, showTitle, hideAnchor, sidebar, slug, featuredVideo, featuredImageType, contributors, contributors2, description, tags, category, breadcrumb, locale } =
        frontmatter
    const filePath = parent?.relativePath
    const contributorsData = contributors || contributors2
    const featuredImg = featuredImage || parent?.cover?.image
    const defaultBreadcrub = [
        { name: 'Blog', url: '/blog' },
        ...(category
            ? [{ name: category, url: `/blog/categories/${slugify(category, { lower: true })}` }]
            : [{}]),
    ]
    // const breadcrumbs = breadcrumb || defaultBreadcrub
    const breadcrumbs = defaultBreadcrub
    const components = {
        ...shortcodes(pageProps),
        h1: (props) => Heading({ as: 'h1', ...props }),
        ul: (props) => UnorderedList({ as: 'ul', ...props }),
        h2: (props) => Heading({ as: 'h2', ...props }),
        h3: (props) => Heading({ as: 'h3', ...props }),
        h4: (props) => Heading({ as: 'h4', ...props }),
        h5: (props) => Heading({ as: 'h5', ...props }),
        h6: (props) => Heading({ as: 'h6', ...props }),
        pre: MdxCodeBlock,
        inlineCode: InlineCode,
        blockquote: Blockquote,
        img: ZoomImage,
        video: (props) => (
            <ZoomImage>
                <video {...props} />
            </ZoomImage>
        ),
        a: A,
        TutorialsSlider,
    }
    const { tableOfContents, menu, brand     } = pageContext
    const dir = getDirection(locale)
  //  const dir = isLangRightToLeft(language) ? "rtl" : "ltr"

    return (
        <Layout >
    <SEO
    title={postData?.frontmatter?.title + ` - ${brand}`}
    description={postData?.frontmatter?.description || postData?.excerpt}
    article
    image={ postData?.frontmatter?.featuredImageType === 'full' ? `/og-images/${postData?.frontmatter?.slug?.replace(/\//g, '')}.jpeg` : featuredImg?.publicURL }
/>
            <PostLayout
                dir={dir}
                stickySidebar
                title={title}
                contentWidth={790}
                filePath={filePath}
                tableOfContents={tableOfContents}
                breadcrumb={breadcrumbs}
                menu={menu}
                hideSurvey
                sidebar={
                    <BlogPostSidebar
                        tags={tags}
                        contributors={contributorsData}
                        date={date}
                        filePath={filePath}
                        title={title}
                        location={location}
                        pageViews={fields?.pageViews}
                    />
                }
            >
                <Intro
                    title={title}
                    featuredImage={featuredImg}
                    featuredVideo={featuredVideo}
                    featuredImageType={featuredImageType}
                    contributors={contributorsData}
                    date={date}
                    tags={tags}
                />
                <div dir="auto" className="article-content">
                    <MDXProvider components={components}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </MDXProvider>
                </div>
            </PostLayout>
        </Layout>
    )
}

export const query = graphql`
query GoogleDocsBlogPostLayout($id: String!) {
  postData: mdx(id: {eq: $id}) {
    id
    body
    excerpt
    fields {
      slug
      pageViews
    }
    frontmatter {
      date
      title
      slug
      sidebar
      showTitle
      locale
      template
      type
      hideAnchor
      breadcrumb {
        name
        slug
      }
      tags
      name
      category
      description
      featuredImageType
      featuredImage {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      featuredVideo
            contributors2: authorData2 {
                    id
                                  name
              role
              handle
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 38, height: 38)
                        }
                    }
                }
      contributors: authorData {
        frontmatter {
          handle
          link_type
        link_url
        name
          role: jobTitle
          image {
            childrenImageSharp {
            gatsbyImageData(width: 38, height: 38)
            }
          }
        }
        id
        name
        parent {
          ... on GoogleDocs {
            cover {
              image {
                publicURL
                childImageSharp {
                    gatsbyImageData(width: 38, height: 38)
                }
              }
            }
          }
        }
      }
    }
    parent {
      ... on GoogleDocs {
        id
        cover {
          image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}
`
